import React from 'react';
import Status from './Status';

function NotFound() {
  return (
    <Status code={404}>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <a
          style={{ display: 'inline-block', textAlign: 'center' }}
          href={process.env.RAZZLE_WEBSITE_DOMAIN}
          target="_self"
          rel="noopener noreferrer"
        >
          <span
            style={{
              width: '2.5rem',
              height: '2.5rem',
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            <img style={{ width: '100%' }} src="/images/thimble.gif" alt="Thimble Logo" />
          </span>
          <span
            style={{
              height: '1.625rem',
              display: 'inline-block',
              verticalAlign: 'text-bottom',
            }}
          >
            <img style={{ height: '100%' }} src="/images/thimble_text.svg" alt="Thimble" />
          </span>
        </a>
        <h1 style={{ textAlign: 'center' }}>404 Page Not Found</h1>
      </main>
    </Status>
  );
}

export default NotFound;
