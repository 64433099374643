import React from 'react';
import { Route } from 'react-router-dom';

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        // There is no `staticContext` on the client, so we need to guard against that here
        // (`staticContext` will be undefined if rendered through <BrowserRouter />)
        if (staticContext) {
          staticContext.status = code;
        }
        return <>{children}</>;
      }}
    />
  );
}

export default Status;
