import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import NotFound from '../../components/NotFound';
import './App.css';

const App = ({ routes, initialData }) => (
  <Switch>
    {routes.map((route, index) => {
      // pass in the initialData from the server or window.DATA for this specific route
      return (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={(props) =>
            React.createElement(route.component, {
              ...props,
              initialData: initialData[index] || null,
            })
          }
        />
      );
    })}
    <RedirectWithStatus path="/user/:id" to={process.env.RAZZLE_WEBSITE_DOMAIN} status={301} />
    <Route component={NotFound} />
  </Switch>
);

export default App;
