import { BrowserRouter, Route } from 'react-router-dom';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import routes from './routes';
import App from './containers/App/App';
import withTracker from './components/withTracker';

const data = window._INITIAL_DATA_;

const container = document.getElementById('root');

hydrateRoot(
  container,
  <BrowserRouter>
    <Route component={withTracker(App, { routes, initialData: data })} />
  </BrowserRouter>
);

if (module.hot) {
  module.hot.accept();
}
