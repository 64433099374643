// Test url: https://link-test.thimble.com/user/referral/574a67edbdefd5c832000699

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isEmpty } from 'lodash';
import withSSR from '../../components/withSSR';

class UserReferralEmailPage extends Component {
  static async getInitialData({ match, req, res }) {
    if (match.params.id) {
      const userId = match.params.id;
      const options = {
        method: 'GET',
        headers: {
          'X-API': 'api-user',
        },
      };
      const response = await fetch(`${process.env.RAZZLE_API_USER}/user/${userId}`, options);
      if (response.status !== 200) {
        throw new Error('User Not Found');
      }

      const user = await response.json();
      if (!user.feature_customer_referral) {
        let utmCampaign = user.verifly_id;
        if (!isEmpty(req.query.utm_campaign)) {
          utmCampaign = req.query.utm_campaign;
        }
        const utmParams = `utm_campaign=${utmCampaign}&utm_source=share&utm_medium=webapp`;
        return {
          url: `${process.env.RAZZLE_WEBAPP_DOMAIN}?${utmParams}`,
        };
      } else {
        return {
          url: process.env.RAZZLE_WEBSITE_DOMAIN,
        };
      }
    }
  }

  render() {
    return <Redirect to={this.props.url} />;
  }
}

export default withSSR(UserReferralEmailPage);
