import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function RedirectWithStatus({ path, from, to, status }) {
  return (
    <Route
      path={path}
      render={({ staticContext }) => {
        // There is no `staticContext` on the client, so we need to guard against that here
        // (`staticContext` will be undefined if rendered through <BrowserRouter />)
        if (staticContext) {
          staticContext.status = status;
        }
        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

export default RedirectWithStatus;
